<template>
  <div v-if="regionUser">
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pb-2 mb-3 border-bottom">
      <div class="page-header">
        <h4 id="top">Editing Region User: <small>{{ regionUser.user_name }}</small> - <small>{{ regionUser.region_name }}</small></h4>
      </div>
    </div>

    <!-- Error Alert -->
    <danger-alert :errors="errors"></danger-alert>

    <div class="form-group text-left">
      <label>Default?</label>
      <b-checkbox v-model="regionUser.default"></b-checkbox>
    </div>

    <div class="footer float-right">
      <div class="btn-group">
        <router-link :to="{ name: 'RegionUserShow', params: { id: this.$route.params.id } }" tag="button" class="btn btn-secondary" type="button">Cancel</router-link>
        <button v-on:click="updateRegionUser" type="button" class="btn btn-success">Update Region User</button>
      </div>
    </div>

    <br>

  </div>
  <loading-overlay v-else></loading-overlay>
</template>
<script>
import { RegionUserService } from '@/common/services/region_user.service';
import DangerAlert from '@/common/components/danger-alert.vue';
import LoadingOverlay from '@/common/components/loading-overlay.vue';

export default {
  name: 'RegionUserEdit',
  components: {
    DangerAlert,
    LoadingOverlay,
  },
  data() {
    return {
      id: null,
      regionUser: null,
      errors: [],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getRegionUser();
  },
  methods: {
    getRegionUser() {
      RegionUserService.get(this.id)
        .then((response) => {
          this.regionUser = response.data;
        });
    },
    updateRegionUser() {
      RegionUserService.update(this.id, this.regionUser).then(({ data }) => {
        this.$router.push({ name: 'RegionUserShow', params: { id: this.id } });
        this.$notify({
          title: 'Edited Region User',
          text: `Successfully edited ${data.user_name}!`,
          type: 'success',
          width: 350,
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
